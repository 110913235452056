const { $, $$ } = window // eslint-disable-line

export class Masonry {
  constructor(container, cellSelector) {
    this.container = container
    this.cellSelector = cellSelector

    this.addEventListeners()
  }

  resizeGridItems = () => {
    let rowStyle = getComputedStyle(this.container)
    let rowGap = parseInt(rowStyle.gridRowGap, 10)
    let rowHeight = parseInt(rowStyle.gridAutoRows, 10) + rowGap
    $$(this.cellSelector, this.container).forEach((el) => {
      const childElements = $$(el.children)
      let elHeight = childElements.reduce((height, child) => {
        return child.getBoundingClientRect().height + height
      }, 0)

      let rowSpan = Math.ceil((elHeight + rowGap) / rowHeight)

      el.style.gridRowEnd = `span ${rowSpan}`
    })
  }

  addEventListeners = () => {
    document.addEventListener("DOMContentLoaded", this.resizeGridItems)
    window.addEventListener("load", this.resizeGridItems)
    window.addEventListener("resize", () => {
      this.resizeGridItems()
    })
  }
}
