/* global $, $$ */

import moment from "moment"
import datepicker from "js-datepicker"

function arrayUnique(array) {
  let a = array.concat()
  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1)
    }
  }

  return a
}

function getDates(startDate, stopDate) {
  let dateArray = []
  let currentDate = moment(startDate)
  let lastDate = moment(stopDate)

  while (currentDate <= lastDate) {
    dateArray.push(moment(currentDate).unix() * 1000)
    currentDate = moment(currentDate).add(1, "days")
  }
  return dateArray
}

export function initAgenda() {
  let formCalendar = document.querySelector(".form-calendar")
  let formCalendarInput = document.querySelector(".form-calendar--input")

  if (formCalendar) {
    $(formCalendar)._.delegate("change", "input", () => {
      formCalendar.submit()
    })

    let dateOptions = { year: "numeric", month: "2-digit", day: "2-digit" }
    let eventElements = $$("[data-between]")

    const url = new URL(window.location)
    const selectedDate = url.searchParams.get("day")
      ? new Date(url.searchParams.get("day"))
      : null

    let activeDates = eventElements.reduce(
      (acc, el) =>
        acc.concat(
          getDates(
            el.getAttribute("data-date-start"),
            el.getAttribute("data-date-end"),
          ),
        ),
      [],
    )

    activeDates = arrayUnique(activeDates)

    const options = {
      customDays: ["S", "M", "D", "M", "D", "F", "S"],
      customMonths: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
      ],
      formatter: (input, date) => {
        const value = date.toLocaleDateString("fr-CA", dateOptions)
        input.value = value
      },
      startDay: 1,
      disabler: (d) => {
        return activeDates.indexOf(d.getTime()) === -1
      },
      alwaysShow: true,
      disableYearOverlay: true,
      onSelect: (instance, selectedDate) => {
        formCalendarInput.setAttribute(
          "value",
          selectedDate.toLocaleDateString("fr-CA", dateOptions),
        )
        formCalendar.submit()
      },
      dateSelected: selectedDate,
    }
    datepicker(formCalendarInput, options)
  }
}
